import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Checklist = makeShortcode("Checklist");
const Button = makeShortcode("Button");
const CTA = makeShortcode("CTA");
const Link = makeShortcode("Link");
const Tout = makeShortcode("Tout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Bounties`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illustrations/Robot-1.png",
          "alt": "Bounties"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`Bounties are small to medium tasks that need help. They mostly cover work related to this site. You can earn Dai by completing bounties.`}</h2>
      </Box>
    </InfoBlock>
    <h2>{`Requirements`}</h2>
    <p>{`Most bounties require knowledge of:`}</p>
    <Checklist mdxType="Checklist">
      <p><strong parentName="p">{`GitHub`}</strong>{` We manage the bounty process through Github issues and project boards.`}</p>
      <p><strong parentName="p">{`Google Docs`}</strong>{` Collaborators will review your drafts and early iterations in Google Docs.`}</p>
      <p><strong parentName="p">{`Markdown`}</strong>{` Final site content is written in markdown.`}</p>
      <p><strong parentName="p">{`Maker`}</strong>{` Learn about Maker. The community has created plenty of reading material. `}<a parentName="p" {...{
          "href": "/learn/"
        }}>{`Learn about Maker`}</a>{`.`}</p>
    </Checklist>
    <p>{`Take a look at the projects we have:`}</p>
    <Button primary outline to="https://github.com/makerdao/community/projects/2?card_filter_query=label%3A%22help+wanted%22" mdxType="Button"> See open bounties </Button>
    <CTA mdxType="CTA">
      <h2>{`Start Work`}</h2>
      <p>{`Get started with work in four steps.`}</p>
      <ol>
        <li parentName="ol">{`Get familiar with the requirements.`}</li>
        <li parentName="ol">{`Express interest in the `}<a parentName="li" {...{
            "href": "https://chat.makerdao.com/channel/community-development"
          }}>{`community chat`}</a></li>
        <li parentName="ol">{`Get assigned an Advisor from the community to help.`}</li>
        <li parentName="ol">{`Then get to work.`}</li>
      </ol>
      <p>{`To learn more about the contribution process, see `}<a parentName="p" {...{
          "href": "/contribute/bounties/working-on-a-bounty/"
        }}>{`Working on a Bounty`}</a>{`.`}</p>
    </CTA>
    <h2>{`Payment`}</h2>
    <p>{`Earn up to 500 Dai for a bounty. We’ll discuss payment details with you after interest has been expressed.`}</p>
    <p>{`Ask in the community chat for help submitting an invoice.`}</p>
    <Link icon="rocketchat" to="https://chat.makerdao.com/channel/community-development" mdxType="Link"> Community Development </Link>
    <h3>{`Got a Bigger Idea?`}</h3>
    <p>{`We welcome all ideas to improve this site or the Maker community. Submit a proposal for up to 5,000 Dai in funding.`}</p>
    <p><a parentName="p" {...{
        "href": "/funding/community-projects/"
      }}>{`Community funding proposal`}</a>{`.`}</p>
    <h2>{`Suggested Reading`}</h2>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h3>{`Working on a Bounty`}</h3>
        <p>{`See what’s expected for working on a Maker community bounty.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/bounties/working-on-a-bounty/"
          }}>{`Working on a bounty`}</a></p>
      </Box>
    </Tout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      